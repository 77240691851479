import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { json } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import styled, { css } from 'styled-components'

import Hero from './__components/Hero'
import TheLemonWay from './__components/TheLemonWay'
import ZeroCost from './__components/ZeroCost'

import builderImages from '~/constants/builderImages'
import { getFaqQuestionsByPathname } from '~/constants/faqQuestions'
import paths from '~/constants/paths'
import Faq from '~/sections/Faq'
import Media from '~/sections/Media'
import cacheUtils from '~/utils/cache.utils.server'
import { commonMetaTags } from '~/utils/meta-tags.utils'

const Container = styled.div(() => css``)

export const links = () => [
  {
    rel: 'preload',
    href: builderImages.hero_home,
    as: 'image',
    type: 'image/jpg',
  },
  {
    rel: 'preload',
    href: builderImages.hero_home_mobile,
    as: 'image',
    type: 'image/jpg',
  },
]

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url)

  const ctaButtonLink = new URL(paths.ACQUISITION_FLOW_HOME, url.origin)
  ctaButtonLink.searchParams.set('utm_source', 'marketing_midia_on')
  ctaButtonLink.searchParams.set('utm_medium', 'site')
  ctaButtonLink.searchParams.set('utm_campaign', 'home_organic')

  const body = {
    questions: getFaqQuestionsByPathname(url.pathname),
    ctaButtonLink: ctaButtonLink.toString(),
  }

  const cacheValidation = cacheUtils.validateEtag<typeof body>(
    request,
    JSON.stringify(body),
  )
  if (cacheValidation.cacheHit) return cacheValidation.response

  return json(body, {
    headers: cacheUtils.createCacheHeaders({
      etag: cacheValidation.etag,
      browserStrategy: 'FOR_ONE_MINUTE',
      isPrivate: true,
    }),
  })
}

export const meta: MetaFunction<typeof loader> = ({ data, location }) => {
  const tags = [
    ...commonMetaTags({
      pathname: location.pathname,
    }),
    {
      'script:ld+json': {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'Lemon Energia',
        url: 'https://energialemon.com.br/',
        sameAs: [
          'https://www.linkedin.com/company/lemon-energia',
          'https://www.instagram.com/energialemon',
          'https://www.facebook.com/EnergiaLemon',
          'https://maps.app.goo.gl/Qoh28FX5sSPn585y6',
        ],
        logo: 'https://images.energialemon.com.br/branding/logos/simbolo_branco_fundo_verde.png',
        description:
          'A Lemon oferece soluções em energia sustentável para residências e empresas.',
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: '+55-11-91018-8899',
          contactType: 'Atendimento ao Cliente',
          email: 'contato@energialemon.com.br',
          areaServed: 'BR',
          availableLanguage: ['Português'],
        },
        address: {
          '@type': 'PostalAddress',
          streetAddress:
            'Av. Engenheiro Luís Carlos Berrini, 1.748 - Conj. 1.710 - Cidade Monções',
          addressLocality: 'São Paulo',
          addressRegion: 'SP',
          postalCode: '04571-000',
          addressCountry: 'BR',
        },
        legalName: 'LEMON LVM ENERGIAS RENOVÁVEIS LTDA',
        vatID: '33.400.689/0001-09',
        makesOffer: {
          '@type': 'Offer',
          url: 'https://energialemon.com.br/quero-economizar',
          priceCurrency: 'BRL',
          availability: 'https://schema.org/InStock',
          description:
            'Energia limpa sem instalação, sem custo extra e com economia todo mês.',
          itemOffered: {
            '@type': 'Service',
            name: 'Energia Limpa e Barata',
            description:
              'Conectamos pessoas e negócios a usinas de energia solar, promovendo descontos na conta de energia ao oferecermos energia mais barata que a distribuidora tradicional. Nossa energia é mais sustentável por vir de usinas solares próximas, evitando o uso de termoelétricas.',
          },
        },
      },
    },
    {
      'script:ld+json': {
        '@context': 'https://schema.org',
        '@type': 'SoftwareApplication',
        name: 'Lemon Energia - Android',
        operatingSystem: 'Android',
        applicationCategory: 'Utilities',
        downloadUrl: 'https://lmn.page/lemonapp-google-play',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '4.3',
          bestRating: '5',
          ratingCount: '83',
        },
      },
    },
    {
      'script:ld+json': {
        '@context': 'https://schema.org',
        '@type': 'SoftwareApplication',
        name: 'Lemon Energia - iOS',
        operatingSystem: 'iOS',
        applicationCategory: 'Utilities',
        downloadUrl: 'https://lmn.page/lemonapp-app-store',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '4.9',
          bestRating: '5',
          ratingCount: '48',
        },
      },
    },
  ]

  if (!data?.questions) return tags

  return [
    ...tags,
    {
      'script:ld+json': {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: data.questions.map(question => ({
          '@type': 'Question',
          name: question.question,
          acceptedAnswer: {
            '@type': 'Answer',
            text: question.answer,
          },
        })),
      },
    },
  ]
}

export default function Index() {
  const { ctaButtonLink } = useLoaderData<typeof loader>()

  return (
    <Container>
      <Hero ctaButtonLink={ctaButtonLink} />
      <TheLemonWay id="energia-do-jeito-lemon" />
      <ZeroCost />
      <Media />
      <Faq />
    </Container>
  )
}
